<template>
<div>
    <Contact />
</div>
</template>

<script>
import Contact from '../../components/dashboard/helpSupport/Contact.vue'
export default {
    components: {
        Contact
    }
}
</script>

<style>
    