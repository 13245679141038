<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <div slot="content">

            <div class="container">

                <div class="contact-div">
                    <div class="email-div">
                        <div class="pb-4">
                            <img src="../../../assets/images/logo2.png" height="80">
                        </div>
                            <a href="mailto: support@requid.com">

                            <div class="message-box mb-4 mt-4">
                                <div class="icon-div">
                                    <img src="../../../assets/images/message.png" height="40">
                                </div>
                                    <div class="contact-mes">
                                        <p class="head-font pb-2">{{tran.email}}</p>
                                        <p>{{tran.info_req}}</p>
                                    </div>
                                    <div class="circle-div">

                                        <img src="../../../assets/images/circles.png" height="80">
                                    </div>
                                    </div>
                                   </a>

                                <a href="tel: 014-537-990">
                                    <div class="message-box mb-4">
                                        <div class="icon-div">
                                            <img src="../../../assets/images/message2.png" height="40">
                                        </div>
                                            <div class="contact-mes">
                                                <p class="head-font pb-2">{{tran.customer_service}}</p>
                                                <p>{{tran.customer_service_number}}</p>
                                            </div>
                                            <div class="circle-div">
                                                <img src="../../../assets/images/circles.png" height="80">
                                             </div>
                                            </div>
                                        </a>
                                                     <a href="https://api.whatsapp.com/send?phone= +234-708-528-3475">
                                            <div class="message-box mb-4">
                                                <div class="icon-div">
                                                    <img src="../../../assets/images/message3.png" height="40">
                                                    </div>
                                                    <div class="contact-mes">
                                                        <p class="head-font pb-2">{{tran.whats_app}}</p>
                                                        <p>{{tran.whats_app_num}}</p>
                                                    </div>
                                                    <div class="circle-div">
                                                        <img src="../../../assets/images/circles.png" height="80">
                                                     </div>
                                                    </div>

                                                        </a>
                                                </div>
                                                <!-- <div class="contact-support">
                                                    <h3 class="head-font">{{tran.contact_support}}</h3>

                                                    <p class="pt-4 pb-4"><b>{{tran.please_type_your}}</b></p>
                                                    <form action class="form">
                                                        <div class="form-group">
                                                            <label class="form-label" for="first">name</label>
                                                            <input  class="form-input" type="email">
                                                        </div>
                                                    </form>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'

import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";

import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {

        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Sidebar,
        Wrapper,
   
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

    },
    mounted() {
        let heading = {

            main: this.tran.contact_support,
        }
        this.$store.commit('market/savePageHeading', heading)
        this.tran = trans;

    },
}
</script>

<style scoped>
a{

    min-width: 100%;
    color: #000;
    padding: 0px;
}
</style>
